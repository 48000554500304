import React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import NotFound from '../components/notFound'

import '../sass/app.scss';

const NotFoundPage = ({ location }) => {

  const { t } = useTranslation()
  const data = t("global", { returnObjects: true })
  const breakpoints = useBreakpoint();

  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={data} noFooter={true} page={data.notfound.page} seo={data.notfound.seo} noindex >

      {breakpoints.mobile ? <MenuGlobal video src="http://news.invisual.pt/wp/wp-content/uploads/2024/10/404_versao_mobile_ja9yms-1.mp4"
        global={data} mobile noFilter location={location} noScroll />
        : <MenuGlobal video src="http://news.invisual.pt/wp/wp-content/uploads/2024/10/404_versao_desktop_soy4de-1.mp4"
          global={data} noFilter location={location} noScroll />
      }
        <NotFound data={data.notfound} />
    </Layout>
  )
}

export default NotFoundPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`